import { toast } from "react-toastify";

const ShowToast = ({
  type = "info",
  message,
  position = "top-right",
  autoClose = 5000,
}) => {
  const options = {
    position,
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    case "warn":
      toast.warn(message, options);
      break;
    case "info":
    default:
      toast.info(message, options);
      break;
  }
};

export default ShowToast;
