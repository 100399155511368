import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { BsAlarmFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setTimerModal } from "../../../slices/modalSlice";

const TimerModal = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { isTimerCompletedModal } = useSelector((state) => state.modalReducer);

  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    let timerId;

    if (isTimerCompletedModal) {
      timerId = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(timerId);
            console.log("called handleSubmit");
            handleSubmit();
            dispatch(setTimerModal(false));
          }
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => {
      if (isTimerCompletedModal) {
        clearInterval(timerId);
      }
    };
  }, [isTimerCompletedModal, dispatch, handleSubmit]);

  return (
    isTimerCompletedModal && (
      <div className="timer_modal">
        <div className="modal_box">
          <div className="timer">
            <span className="time">{countdown}</span>
          </div>
          <div className="timer_text">
            <p>Time has been completed..</p>
            <div className="timer_icon">
              <BsAlarmFill color="white" size={28} />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default TimerModal;
