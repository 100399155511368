import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlices";
//reducers
import authReducer from "./slices/authSlice";
import modalReducer from "./slices/modalSlice";
import timerReducer from "./slices/timerSlice";

const store = configureStore({
  reducer: {
    authReducer,
    modalReducer,
    timerReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    apiSlice.middleware,
  ],
  //on production change it to false
  devTools: process.env.NODE_ENV === "development",
});

export default store;
