const toggleMenuHelper = (shouldCollapse) => {
  const body = document.body;
  if (shouldCollapse) {
    body.classList.add("layout-menu-collapsed");
  } else {
    body.classList.remove("layout-menu-collapsed");
  }
};

const McqsJsonData2 = {
  sections: [
    {
      key: "_id",
      title: "Science",
      questions: [
        {
          key: "__",
          type: "multiple-choice",
          ques: "What is the boiling point of water?",
          answers: ["90°C", "95°C", "100°C", "105°C"],
        },
        {
          key: "__",
          type: "multiple-choice",
          ques: "Which gas is most abundant in the Earth's atmosphere?",
          answers: ["Oxygen", "Hydrogen", "Nitrogen", "Carbon Dioxide"],
        },
        {
          type: "multiple-choice",
          ques: "What is the formula for water?",
          answers: ["H2O", "HO2", "H2O2", "H3O"],
        },
        {
          type: "multiple-choice",
          ques: "Which vitamin is known as ascorbic acid?",
          answers: ["Vitamin A", "Vitamin B", "Vitamin C", "Vitamin D"],
        },
        {
          type: "multiple-choice",
          ques: "What is the primary source of energy for the Earth?",
          answers: ["Wind", "Water", "Sun", "Coal"],
        },
      ].concat(
        Array(10).fill({
          type: "multiple-choice",
          ques: "Example question",
          answers: ["Option 1", "Option 2", "Option 3", "Option 4"],
        })
      ),
    },
    {
      key: "_id",
      title: "Math",
      questions: [
        {
          key: "_id",
          type: "multiple-choice",
          ques: "What is 10 + 15?",
          answers: ["25", "20", "15", "30"],
        },
        {
          type: "multiple-choice",
          ques: "What is the square root of 144?",
          answers: ["12", "14", "16", "18"],
        },
        {
          type: "multiple-choice",
          ques: "What is 7 * 8?",
          answers: ["54", "56", "58", "60"],
        },
        {
          type: "multiple-choice",
          ques: "What is 100 divided by 25?",
          answers: ["2", "4", "6", "8"],
        },
      ].concat(
        Array(6).fill({
          type: "multiple-choice",
          ques: "Example question",
          answers: ["Option 1", "Option 2", "Option 3", "Option 4"],
        })
      ),
    },
  ],
};

//long
const McqsJsonDatas = {
  sections: [
    {
      key: "_id",
      title: "Science",
      questions: [
        {
          _id: "science_ques_1",
          type: "multiple-choice",
          ques: "Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.",
          answers: ["90°C", "95°C", "100°C", "105°C"],
        },
        {
          _id: "science_ques_2",
          type: "multiple-choice",
          ques: "Which gas is most abundant in the Earth's atmosphere?",
          answers: ["Oxygen", "Hydrogen", "Nitrogen", "Carbon Dioxide"],
        },
        {
          _id: "science_ques_3",
          type: "multiple-choice",
          ques: "What is the formula for water?",
          answers: ["H2O", "HO2", "H2O2", "H3O"],
        },
        {
          _id: "science_ques_4",
          type: "multiple-choice",
          ques: "What is the formula for water 4444444?",
          answers: ["H2O", "HO2", "H2O2", "44444444"],
        },
        {
          _id: "science_ques_5",
          type: "multiple-choice",
          ques: "What is the formula for water 555555555555?",
          answers: ["H2O", "HO2", "H2O2", "55555"],
        },
      ].concat(
        Array(25).fill({
          type: "multiple-choice",
          ques: "Example question",
          answers: ["Option 1", "Option 2", "Option 3", "Option 4"],
        })
      ),
    },
    {
      key: "_id",
      title: "Math",
      questions: [
        {
          _id: "math_ques_1",
          type: "multiple-choice",
          ques: "What is 10 + 15?",
          answers: ["25", "20", "15", "30"],
        },
        {
          _id: "math_ques_2",
          type: "multiple-choice",
          ques: "What is the square root of 144?",
          answers: ["12", "14", "16", "18"],
        },
        {
          _id: "math_ques_3",
          type: "multiple-choice",
          ques: "What is 7 * 8?",
          answers: ["54", "56", "58", "60"],
        },
        {
          _id: "math_ques_4",
          type: "multiple-choice",
          ques: "What is 100 divided by 25?",
          answers: ["2", "4", "6", "8"],
        },
      ].concat(
        Array(24).fill({
          type: "multiple-choice",
          ques: "Example question",
          answers: ["Option 1", "Option 2", "Option 3", "Option 4"],
        })
      ),
    },
  ],
};

//short
const McqsJsonData = {
  sections: [
    {
      key: "_id",
      title: "Science",
      questions: [
        {
          _id: "science_ques_1",
          type: "multiple-choice",
          ques: "Explain the process of osmosis in plant cells and its role in maintaining turgor pressure.",
          answers: ["90°C", "95°C", "100°C", "105°C"],
        },
        {
          _id: "science_ques_2",
          type: "multiple-choice",
          ques: "Which gas is most abundant in the Earth's atmosphere?",
          answers: ["Oxygen", "Hydrogen", "Nitrogen", "Carbon Dioxide"],
        },
        {
          _id: "science_ques_3",
          type: "multiple-choice",
          ques: "What is the formula for water?",
          answers: ["H2O", "HO2", "H2O2", "H3O"],
        },
        {
          _id: "science_ques_4",
          type: "multiple-choice",
          ques: "What is the formula for water 4444444?",
          answers: ["H2O", "HO2", "H2O2", "44444444"],
        },
        {
          _id: "science_ques_5",
          type: "multiple-choice",
          ques: "What is the formula for water 555555555555?",
          answers: ["H2O", "HO2", "H2O2", "55555"],
        },
      ],
    },
    {
      key: "_id",
      title: "Math",
      questions: [
        {
          _id: "math_ques_1",
          type: "multiple-choice",
          ques: "What is 10 + 15?",
          answers: ["25", "20", "15", "30"],
        },
        {
          _id: "math_ques_2",
          type: "multiple-choice",
          ques: "What is the square root of 144?",
          answers: ["12", "14", "16", "18"],
        },
        {
          _id: "math_ques_3",
          type: "multiple-choice",
          ques: "What is 7 * 8?",
          answers: ["54", "56", "58", "60"],
        },
        {
          _id: "math_ques_4",
          type: "multiple-choice",
          ques: "What is 100 divided by 25?",
          answers: ["2", "4", "6", "8"],
        },
      ],
    },
  ],
};

const findSectionIndexByTitle = (title, sections) => {
  return sections.findIndex((section) => section.category === title);
};

function getColorCode(allAnswersArr, itemId) {
  // const statusColorMap = {
  //   skipped: "#C8381E",
  //   answered_And_Justified: "#78DB21",
  //   answered_ButNot_Justified: "#F0A930",
  //   answered_Justify_And_For_Review: "black",
  //   not_Answered_And_InReview: "#DE16B9",
  //   answered_NotJustified_InReview: "#1E25C8",
  // };
  const statusColorMap = {
    skipped: "#C8381E",
    answered_And_Justified: "#78DB21",
    answered_ButNot_Justified: "#1E24C8",
    answered_Justify_And_For_Review: "#F0A930",
    not_Answered_And_InReview: "#F0A930",
    answered_NotJustified_InReview: "#F0A930",
    markedForReview: "#F0AA30",
  };
  // console.log(allAnswersArr, " getColor code");
  // return;

  if (!allAnswersArr.length) return "";

  const item = allAnswersArr
    .filter((o) => o)
    .find(
      (answer) =>
        answer.currentQuestion && answer.currentQuestion.question_id === itemId
    );

  if (!item) return "";

  //
  if (item.not_Answered_And_InReview && item.skipped) {
    return statusColorMap.not_Answered_And_InReview;
  }

  let colorCode = "";

  for (let status in statusColorMap) {
    if (item[status]) {
      colorCode = statusColorMap[status];
      break;
    }
  }

  return colorCode || "";
}

// payload
// const transformAnswerToPayload = (userObj, answerArr) => {
//   console.log({ answerArr }, " annArrr");
//   const responsePayload = {
//     ...userObj,
//     responses: {},
//   };

//   answerArr?.filter((o) => o);

//   answerArr.forEach((section) => {
//     section.forEach((item) => {
//       // Extract
//       const { answer, currentSectionType, justifyText, currentQuestion } = item;

//       // Initialize the section in response if not already initialized
//       if (!responsePayload.responses[currentSectionType.toLowerCase()]) {
//         responsePayload.responses[currentSectionType.toLowerCase()] = [];
//       }
//       // console.log({ item });

//       if (answer && answer.text) {
//         //obj to push
//         const response = {
//           question_id: currentQuestion.question_id,
//           // option_selected: "AAA",
//           option_selected: answer.text
//             ? answer.currentQuestion.options.find(
//                 (opt) => opt.text === answer.text
//               )?.option
//             : null,
//           justification: justifyText ? justifyText : null,
//         };

//         // Only add to payload if option_selected is not null
//         if (response.option_selected !== null) {
//           responsePayload.responses[currentSectionType.toLowerCase()].push(
//             response
//           );
//         }
//       }
//     });
//   });
//   // console.log({ responsePayload, answerArr, userObj }, " from helperrr");
//   return responsePayload;
// };

const transformAnswerToPayload = (userObj, answerArr) => {
  // console.log({ answerArr }, " annArrr");

  const responsePayload = {
    ...userObj,
    responses: {},
  };

  // Filter out null or undefined sections and items
  const validAnswerArr = answerArr
    ?.filter(Boolean)
    .map((section) => section.filter(Boolean));

  validAnswerArr.forEach((section) => {
    section.forEach((item) => {
      // Extract
      const { answer, currentSectionType, justifyText, currentQuestion } = item;

      // Initialize the section in response if not already initialized
      if (!responsePayload.responses[currentSectionType.toLowerCase()]) {
        responsePayload.responses[currentSectionType.toLowerCase()] = [];
      }

      if (answer && answer.text) {
        // Object to push
        const response = {
          question_id: currentQuestion.question_id,
          option_selected: answer.text
            ? currentQuestion.options.find((opt) => opt.text === answer.text)
                ?.option
            : null,
          justification: justifyText ? justifyText : null,
        };

        // Only add to payload if option_selected is not null
        if (response.option_selected !== null) {
          responsePayload.responses[currentSectionType.toLowerCase()].push(
            response
          );
        }
      }
    });
  });

  // console.log({ responsePayload, answerArr, userObj }, " from helperrr");
  return responsePayload;
};

// const filterValidAnswers = (answers) => {
//   return answers.map((categoryAnswers) =>
//     categoryAnswers.filter((answer) => answer)
//   );
// };
const filterValidAnswers = (answers) => {
  return answers
    .filter((categoryAnswers) => categoryAnswers)
    .map((categoryAnswers) => categoryAnswers.filter((answer) => answer));
};

//filter option
const filterOptions = [
  "Skipped",
  "Answered & justified",
  "Answered but not justified",
  "Answered, justified & in review",
  "Not answered & in review",
  "Answered, not justified & in review",
];

const filterOptionsEarlier = {
  Skipped: "skipped",
  "Answered & justified": "answered_And_Justified",
  "Answered but not justified": "answered_ButNot_Justified",
  "Answered, justified & in review": "answered_Justify_And_For_Review",
  "Not answered & in review": "not_Answered_And_InReview",
  "Answered, not justified & in review": "answered_NotJustified_InReview",
};

const filterOptions2 = {
  Skipped: "skipped",
  "Answered & justified": "answered_And_Justified",
  "Answered but not justified": "answered_ButNot_Justified",
  "Not visited": "not_visited",
  //
  // "Answered, justified & in review": "answered_Justify_And_For_Review",
  // "Not answered & in review": "not_Answered_And_InReview",
  // "Answered, not justified & in review": "answered_NotJustified_InReview",
  "Marked For Review": "markedForReview",
};

function filterQuestions(userSelection, answers, stubsData) {
  // const filterKey = filterMapping[userSelection]; // Get the corresponding status key
  const filterKey = userSelection; // Get the corresponding status key

  // Find all answers that match the filter
  const filteredAnswers = filterValidAnswers(answers).reduce(
    (acc, category) => {
      const matchingAnswers = category.filter((answer) => answer[filterKey]);
      acc.push(...matchingAnswers);
      return acc;
    },
    []
  );

  // Get the question IDs from the filtered answers
  const questionIds = filteredAnswers.map(
    (answer) => answer.currentQuestion.question_id
  );

  // Retrieve the corresponding questions from stubsData
  const filteredQuestions = stubsData.questionsListAsPerCategory.flatMap(
    (category) =>
      category.questions.filter((question) =>
        questionIds.includes(question.question_id)
      )
  );

  console.log(filteredQuestions, " filteredQuestions");

  return filteredQuestions;
}

function filterQuestionsByCategory(userSelection, answers, stubsData) {
  const filterKey = userSelection; // Get the corresponding status key

  // Find all answers that match the filter and categorize them
  const filteredAnswersByCategory = filterValidAnswers(answers).reduce(
    (acc, category, index) => {
      const matchingAnswers = category.filter((answer) => answer[filterKey]);
      if (matchingAnswers.length > 0) {
        acc[stubsData.questionsListAsPerCategory[index].key] =
          matchingAnswers.map((answer) => answer.currentQuestion.question_id);
      }
      return acc;
    },
    {}
  );

  // Use the filtered answer IDs to get the questions, maintaining categories
  const filteredQuestions = stubsData.questionsListAsPerCategory
    .map((category) => {
      if (filteredAnswersByCategory[category.key]) {
        return {
          key: category.key,
          category: category.category,
          questions: category.questions.filter((question) =>
            filteredAnswersByCategory[category.key].includes(
              question.question_id
            )
          ),
        };
      }
      return null;
    })
    .filter((category) => category !== null); // Remove any null entries (categories without matches)

  return filteredQuestions;
}

function filterQuestionsByCategoryMultiStatusEarlier(
  userSelections,
  answers,
  stubsData
) {
  // Convert user selections to their corresponding status keys in the answers structure
  const filterKeys = userSelections.map(
    (selection) => filterOptions2[selection]
  );

  // Find all answers that match any of the filter keys and categorize them
  const filteredAnswersByCategory = filterValidAnswers(answers).reduce(
    (acc, category, index) => {
      const matchingAnswers = category.filter(
        (answer) => filterKeys.some((key) => answer[key]) // Check if any of the selected keys are true for the answer
      );
      if (matchingAnswers.length > 0) {
        if (!acc[stubsData.questionsListAsPerCategory[index].key]) {
          acc[stubsData.questionsListAsPerCategory[index].key] = [];
        }
        acc[stubsData.questionsListAsPerCategory[index].key].push(
          ...matchingAnswers.map((answer) => answer.currentQuestion.question_id)
        );
      }
      return acc;
    },
    {}
  );

  // Use the filtered answer IDs to get the questions, maintaining categories
  const filteredQuestions = stubsData.questionsListAsPerCategory
    .map((category) => {
      if (filteredAnswersByCategory[category.key]) {
        return {
          key: category.key,
          category: category.category,
          questions: category.questions.filter((question) =>
            filteredAnswersByCategory[category.key].includes(
              question.question_id
            )
          ),
        };
      }
      return null;
    })
    .filter((category) => category !== null); // Remove any null entries (categories without matches)

  return filteredQuestions;
}

function filterQuestionsByCategoryMultiStatus(
  userSelections,
  answers,
  stubsData
) {
  // console.log("User Selections:", userSelections);
  // console.log("Answers:", answers);
  // console.log("Stubs Data:", stubsData);

  // Convert user selections to their corresponding status keys in the answers structure
  const filterKeys = userSelections.map(
    (selection) => filterOptions2[selection]
  );

  // Determine if the "not visited" option is selected
  const isNotVisitedSelected = userSelections.includes("Not visited");
  // console.log("Is Not Visited Selected:", isNotVisitedSelected);

  const answeredQuestionIds = new Set(
    answers
      .flat()
      .filter((answer) => answer !== null)
      .map((answer) => answer.currentQuestion.question_id)
  );
  // console.log("Answered Question IDs:", answeredQuestionIds);

  let filteredQuestions = stubsData.questionsListAsPerCategory
    .map((category) => {
      let nonVisitedQuestions = [];
      if (isNotVisitedSelected) {
        nonVisitedQuestions = category.questions.filter(
          (question) => !answeredQuestionIds.has(question.question_id)
        );
        console.log(
          `Non-Visited Questions for category ${category.key}:`,
          nonVisitedQuestions
        );
      }

      if (!isNotVisitedSelected || userSelections.length > 1) {
        const filteredAnswersByCategory = filterValidAnswers(answers).reduce(
          (acc, category, index) => {
            const matchingAnswers = category.filter(
              (answer) => filterKeys.some((key) => answer[key]) // Check if any of the selected keys are true for the answer
            );

            if (!acc[stubsData.questionsListAsPerCategory[index].key]) {
              acc[stubsData.questionsListAsPerCategory[index].key] = [];
            }

            if (matchingAnswers.length > 0) {
              acc[stubsData.questionsListAsPerCategory[index].key].push(
                ...matchingAnswers.map(
                  (answer) => answer.currentQuestion.question_id
                )
              );
            }

            return acc;
          },
          {}
        );

        // console.log("Filtered Answers by Category:", filteredAnswersByCategory);

        const categoryKey = category.key;
        const matchingQuestions = category.questions.filter((question) =>
          filteredAnswersByCategory[categoryKey]
            ? filteredAnswersByCategory[categoryKey].includes(
                question.question_id
              )
            : false
        );

        // console.log(
        //   `Matching Questions for category ${category.key}:`,
        //   matchingQuestions
        // );

        if (isNotVisitedSelected) {
          // Combine non-visited and other filtered questions
          const combinedQuestions = [
            ...nonVisitedQuestions,
            ...matchingQuestions.filter(
              (question) =>
                !nonVisitedQuestions.some(
                  (q) => q.question_id === question.question_id
                )
            ),
          ];
          return {
            key: category.key,
            category: category.category,
            questions: combinedQuestions,
          };
        }

        return {
          key: category.key,
          category: category.category,
          questions: matchingQuestions,
        };
      }

      return {
        key: category.key,
        category: category.category,
        questions: nonVisitedQuestions,
      };
    })
    .filter((category) => category.questions.length > 0); // Remove any categories without questions

  // console.log("Filtered Questions:", filteredQuestions);
  return filteredQuestions;
}

//color code helper for filter n info
const colorClassMap = {
  Skipped: "filter_red",
  "Answered & justified": "filter_green",
  "Answered but not justified": "filter_blue",
  "Answered, justified & in review": "filter_black",
  "Not answered & in review": "filter_pink",
  "Answered, not justified & in review": "filter_blue",
  "Marked For Review": "filter_yellow",
  "Not visited": "filter_gray",
};

// const countFilterValues = (answerArr, filterValue, allQuestion) => {
//   const filterKey = filterOptions2[filterValue];
//   if (!filterKey) return 0;

//   return answerArr.flat().reduce((count, answer) => {
//     if (answer && answer[filterKey]) {
//       count++;
//     }
//     return count;
//   }, 0);
// };

const countFilterValues = (answerArr, filterValue, allQuestions) => {
  const filterKey = filterOptions2[filterValue];
  if (!filterKey) return 0;

  if (filterKey === "not_visited") {
    const allQuestionsFlat = allQuestions.flatMap(
      (category) => category.questions
    );
    const answeredQuestions = new Set(
      answerArr
        .flat()
        .filter((answer) => answer !== null)
        .map((answer) => answer.currentQuestion.question_id)
    );
    const notVisitedCount = allQuestionsFlat.reduce((count, question) => {
      if (!answeredQuestions.has(question.question_id)) {
        count++;
      }
      return count;
    }, 0);
    return notVisitedCount;
  }

  return answerArr.flat().reduce((count, answer) => {
    if (answer && answer[filterKey]) {
      count++;
    }
    return count;
  }, 0);
};

//totalQuestionCount
const getTotalQuestionCount = (categories) => {
  if (!categories || !Array.isArray(categories)) {
    return 0;
  }

  return categories.reduce((total, category) => {
    if (category && Array.isArray(category.questions)) {
      return total + category.questions.length;
    }
    return total;
  }, 0);
};

const getTotalResponseCount = (responseObj) => {
  if (!responseObj || typeof responseObj !== "object") {
    return 0;
  }

  return Object.values(responseObj).reduce((total, responsesArray) => {
    if (Array.isArray(responsesArray)) {
      return total + responsesArray.length;
    }
    return total;
  }, 0);
};

// remove Answer For Current Question
// function removeAnswerForCurrentQuestion(currentQuestion, answers, cb) {
//   // Iterate through the answers array to find and remove the current question's answer
//   for (let i = 0; i < answers.length; i++) {
//     // Check if the answer array contains the current question's answer
//     const answerIndex = answers[i].findIndex(
//       (answer) =>
//         answer.currentQuestion.question_id === currentQuestion.question_id
//     );

//     // If found, remove the answer from the array
//     if (answerIndex !== -1) {
//       answers[i].splice(answerIndex, 1);
//       break; // Exit the loop after removing the answer
//     }
//   }

//   cb(answers);
//   // return answers;
// }

const removeAnswerForCurrentQuestion = (currentQuestion, answers, cb) => {
  const newAnswers = answers.map((answerArr) =>
    answerArr.filter(
      (answer) =>
        answer.currentQuestion.question_id !== currentQuestion.question_id
    )
  );
  cb(newAnswers);
};

// const isAnswerPresentForCurrentQuestion = (currentQuestion, answers) => {
//   for (let i = 0; i < answers.length; i++) {
//     const answerIndex = answers[i].findIndex(
//       (answer) =>
//         answer.currentQuestion.question_id === currentQuestion.question_id
//     );

//     if (answerIndex !== -1) {
//       return true;
//     }
//   }

//   return false;
// };

const isAnswerPresentForCurrentQuestion = (currentQuestion, answers) => {
  if (!currentQuestion || !answers || !Array.isArray(answers)) {
    return false;
  }

  if (!currentQuestion.hasOwnProperty("question_id")) {
    return false;
  }

  for (let i = 0; i < answers?.length; i++) {
    if (Array.isArray(answers[i])) {
      const answerIndex = answers[i]?.findIndex(
        (answer) =>
          answer?.currentQuestion &&
          answer?.currentQuestion?.question_id === currentQuestion?.question_id
      );

      if (answerIndex !== -1) {
        return true;
      }
    }
  }

  return false;
};

const removeLocalStorageItems = (keys) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

const getInitials = (name) => {
  if (!name) return "";
  const words = name.split(" ");
  const initials = words.map((word) => word[0].toUpperCase());
  return initials.join("");
};

export {
  toggleMenuHelper,
  McqsJsonData,
  findSectionIndexByTitle,
  getColorCode,
  //
  transformAnswerToPayload,
  filterValidAnswers,
  //filter
  filterOptions,
  filterOptions2,
  //
  filterQuestions,
  filterQuestionsByCategory,
  filterQuestionsByCategoryMultiStatus,
  //
  colorClassMap,
  countFilterValues,
  //
  getTotalQuestionCount,
  getTotalResponseCount,
  //
  removeAnswerForCurrentQuestion,
  isAnswerPresentForCurrentQuestion,
  //
  removeLocalStorageItems,
  //
  getInitials,
};
