import React from "react";
import {
  findSectionIndexByTitle,
  getColorCode,
} from "../../utils/commonHelper";
import { useSelector } from "react-redux";

const PopulateButton = ({
  title,
  allQuestions,
  currentSectionType,
  currentQuestionIndex,
  currentSectionIndex,
  handleShowCurrentQues,
  allAnswersArr,
}) => {
  const section = allQuestions.questionsListAsPerCategory.find(
    (section) => section.category === title
  );

  const noOfQuestions = useSelector((state) => state.authReducer.noOfQuestions);
  
  if (!section) {
    return null;
  }

  return (
    <ul className="pagination">
      {section.questions.map((item, index) => {
        let prev = 0;
        for (let i = 0; i < noOfQuestions.length; i++) {
          if (noOfQuestions[i][0] === title) {
            break; 
          } else {
            prev += noOfQuestions[i][1];
          }
        }
        let question_no = item.question_id - prev;


        return(
        <li
          className="page-item ques_btn"
          key={`${title}-${index}`}
          onClick={() => {
            handleShowCurrentQues(
              index,
              findSectionIndexByTitle(
                title,
                allQuestions.questionsListAsPerCategory
              )
            );
          }}
        >
          <span
            className="page-link"
            style={{
              backgroundColor:
                getColorCode([...allAnswersArr].flat(), item.question_id) ===
                  "" &&
                currentSectionType === title &&
                currentQuestionIndex === index
                  ? "white"
                  : getColorCode([...allAnswersArr].flat(), item.question_id),
              color:
                getColorCode(allAnswersArr.flat(), item.question_id) !== "" &&
                getColorCode(allAnswersArr.flat(), item.question_id) !==
                  "#f8f9fa"
                  ? "#fff"
                  : "black",
              border:
                getColorCode([...allAnswersArr].flat(), item.question_id) ===
                  "" &&
                currentSectionType === title &&
                currentQuestionIndex === index
                  ? "1px solid #5e16da"
                  : "1px solid #fff",
            }}
          >
            {(question_no).toString().padStart(2, "0")}
          </span>
        </li>
      )})}
    </ul>
  );
};
export default PopulateButton;
