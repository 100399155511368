import React from "react";

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
      <div className="container-fluid">
        <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
          <div>
            © <script>document.write(new Date().getFullYear())</script>2024,
            made with ❤️ by <span className="fw-medium">Assessli</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
