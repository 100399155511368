import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
//images
import authLoginImg from "../../assets/images/illustrations/auth-login-illustration-light.png";
import bgShapeImg from "../../assets/images/illustrations/bg-shape-image-light.png";
import logoDark from "../../assets/images/branding/logo_dark.png";
//slice
import { useSignInMutation } from "../../apiSlices/question_submission_ApiSlice";
import { removeLocalStorageItems } from "../../utils/commonHelper";
import { setUserDetails, setUserInfoTrue } from "../../slices/authSlice";

const LoginScreen = () => {
  //misc
  const navigate = useNavigate();
  //misc
  const dispatch = useDispatch();
  //state
  const [isInpTypeText, setIsInpTypeText] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //slice
  const [signIn, { isLoading }] = useSignInMutation();

  const handleSignIn = async (e) => {
    e.preventDefault();
    console.log({ email, password }, " vall");
    // fetch("http://localhost:3000/sign-in", {
    //   method: "POST", // Assuming you change the method to POST for security
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     email,
    //     password,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => console.log(data, " ddddd"))
    //   .catch((error) => console.error("Error:", error));

    try {
      const result = await signIn({ email, password }).unwrap();
      console.log("Form Data:", result);
      localStorage.setItem("showIntro", false);
      // const keysToRemove = ["remainingTime", "showIntro"];
      // removeLocalStorageItems(keysToRemove);
      dispatch(setUserInfoTrue());
      dispatch(setUserDetails(result?.body));
      window.open("/", "_blank", "noopener,noreferrer");
    } catch (err) {
      console.log(err, " errr");
      // alert(`Sign-In Failed: ${err.data.message}`);
    }
  };

  return (
    <div className="authentication-wrapper authentication-cover authentication-bg">
      <div className="authentication-inner row">
        <div className="d-none d-lg-flex col-lg-7 p-0">
          <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
            <img
              src={authLoginImg}
              alt="auth-login-cover"
              className="img-fluid my-5 auth-illustration"
            />
            <img
              src={bgShapeImg}
              alt="auth-login-cover"
              className="platform-bg"
            />
          </div>
        </div>
        <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
          <div className="w-px-400 mx-auto">
            <div className="app-brand mb-4">
              <Link to="/" className="app-brand-link">
                <span
                  className="app-brand-logo demo"
                  style={{ height: "56px", width: "56px" }}
                >
                  <img
                    src={logoDark}
                    alt="brand_logo"
                    style={{ height: "100%" }}
                  />
                </span>
              </Link>
            </div>
            <h3 className="mb-1">Welcome to Assessli! 👋</h3>
            <p className="mb-4">
              Please sign-in to your account and start the adventure
            </p>
            <form
              id="formAuthentication"
              className="mb-3"
              onSubmit={handleSignIn}
            >
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email or Username
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email-username"
                  placeholder="Email/Username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="mb-3 form-password-toggle">
                <div className="d-flex justify-content-between">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="input-group input-group-merge has-validation">
                  <input
                    type={isInpTypeText ? "text" : "password"}
                    id="password"
                    className="form-control"
                    name="password"
                    placeholder="············"
                    aria-describedby="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="input-group-text cursor-pointer"
                    onClick={() => setIsInpTypeText((prev) => !prev)}
                  >
                    <i
                      className={`ti ti-eye${isInpTypeText ? "" : "-off"}`}
                    ></i>
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="remember-me"
                  />
                  <label className="form-check-label" htmlFor="remember-me">
                    Remember Me
                  </label>
                </div>
              </div>
              <button
                className="btn btn-primary d-grid w-100 waves-effect waves-light"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Signing In..." : "Sign in"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
