import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "modal",
  isConfirmationModal: false,
  isSuccessModal: false,
  isErrorModal: false,
  isReviewModal: false,
  isTimerCompletedModal: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setConfirmationModal: (state, action) => {
      state.isConfirmationModal = action.payload;
    },
    setSuccessModal: (state, action) => {
      state.isSuccessModal = action.payload;
    },
    setErrorModal: (state, action) => {
      state.isErrorModal = action.payload;
    },
    setReviewModal: (state, action) => {
      state.isReviewModal = action.payload;
    },
    setTimerModal: (state, action) => {
      state.isTimerCompletedModal = action.payload;
    },
  },
});

export const {
  setConfirmationModal,
  setSuccessModal,
  setErrorModal,
  setReviewModal,
  setTimerModal,
} = modalSlice.actions;

export default modalSlice.reducer;
