import React from "react";

const InfoSection = () => {
  return (
    <div className="info_section">
      <div className="inline_info">
        <span className="filter_red color_span"></span>
        <p>Skipped</p>
      </div>
      <div className="inline_info">
        <span className="filter_green color_span"></span>
        <p>Answered & justified</p>
      </div>
      <div className="inline_info">
        <span className="filter_blue color_span"></span>
        <p>Answered but not justified</p>
      </div>
      <div className="inline_info">
        <span className="filter_gray color_span"></span>
        <p>Not visited</p>
      </div>
      <div className="inline_info">
        <span className="filter_yellow color_span"></span>
        <p>Marked for review</p>
      </div>
    </div>
  );
};

export default InfoSection;
