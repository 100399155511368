import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faInfoCircle,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
//component
import PopulateButton from "./populateButton";
import FilterDropdown from "./filterDropdown";
import InfoSection from "./infoSection";

const RightSection = ({
  allQuestions,
  currentSectionType,
  currentQuestionIndex,
  currentSectionIndex,
  handleShowCurrentQues,
  //
  currentQuestion,
  lastQuestionOfLastCategory,
  handleNext,
  answers: allAnswersArr,
  lastQuestion,
  handleSubmit,
  setIsSubmit,
  //filter
  isFilter,
  setIsFilter,
  filterStatus,
  setFilterStatus,
  //info
  isInfo,
  setIsInfo,
}) => {
  //state
  const [collapsedSections, setCollapsedSections] = useState([]);

  //methods
  const toggleCollapse = (index) => {
    const currentIndex = collapsedSections.indexOf(index);
    const newCollapsedSections = [...collapsedSections];

    if (currentIndex === -1) {
      newCollapsedSections.push(index);
    } else {
      newCollapsedSections.splice(currentIndex, 1);
    }

    setCollapsedSections(newCollapsedSections);
  };

  return (
    <div className="card col-3 py-3 assesment_mcqs_button_container">
      <div className="question_type_section_wrapper">
        <div className="filter_container py-2">
          <div
            className="left_section"
            style={{
              padding: "5px",
              border: isFilter ? "1px solid #9968E7" : "",
              borderRadius: "4px",
            }}
            onClick={() => {
              setIsFilter((prev) => !prev);
              setIsInfo(false);
            }}
          >
            <FontAwesomeIcon icon={faFilter} />
            <span className="type">Filter</span>
            <span
              className="filter_count"
              onClick={() => console.log({ filterStatus }, " fff")}
            >
              {filterStatus?.length || 0}
            </span>
          </div>
          <div
            className="right_section"
            style={{
              padding: "5px",
              border: isInfo ? "1px solid #9968E7" : "",
              borderRadius: "4px",
            }}
            onClick={() => {
              setIsInfo((prev) => !prev);
              setIsFilter(false);
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            <span className="type">Info</span>
          </div>
        </div>

        {/* filter n info section */}
        {isFilter && !isInfo ? (
          <FilterDropdown
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            allAnswersArr={allAnswersArr}
            allQuestions={allQuestions.questionsListAsPerCategory}
          />
        ) : isInfo && !isFilter ? (
          <InfoSection />
        ) : null}
        {/* filter n info section */}

        <div className="question_type_section_item_wrapper">
          {allQuestions?.questionsListAsPerCategory?.map((item, index) => {
            const { category } = item;
            const isCollapsed = collapsedSections.includes(index);

            return (
              <div key={index} className="question_type_section_item px-3">
                <div
                  className="title_container"
                  onClick={() => toggleCollapse(index)}
                >
                  <h4 className="title">
                    Section {index + 1}: <strong>{category}</strong>
                  </h4>
                  <span>
                    <FontAwesomeIcon icon={isCollapsed ? faPlus : faMinus} />
                  </span>
                </div>

                {!isCollapsed && (
                  <ul
                    id="pagination"
                    className="pagination gap-2"
                    style={{ flexWrap: "wrap" }}
                  >
                    <PopulateButton
                      title={category}
                      allQuestions={allQuestions}
                      currentSectionType={currentSectionType}
                      currentQuestionIndex={currentQuestionIndex}
                      currentSectionIndex={currentSectionIndex}
                      handleShowCurrentQues={handleShowCurrentQues}
                      //
                      allAnswersArr={allAnswersArr}
                      lastQuestion={lastQuestion}
                    />
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="submit_btn_container">
        <button
          type="button"
          id="submitBtn"
          className="btn btn-primary me-sm-2 waves-effect waves-light submit_btn mx-3"
          // onClick={() => handleNext(true)}
          onClick={() => {
            // handleNext(true);
            setIsSubmit(true);
          }}
          // disabled={
          //   currentQuestion?.question_id !==
          //   lastQuestionOfLastCategory?.question_id
          // }
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default RightSection;
