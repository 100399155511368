import React, { useEffect, useState } from "react";
//images
import logoDark from "../../assets/images/branding/logo_dark.png";
import { removeLocalStorageItems } from "../../utils/commonHelper";
import { useSelector } from "react-redux";
import {
  useDownloadFileQuery,
} from "../../apiSlices/question_submission_ApiSlice";

const AssesmentIntro = ({ toggleFullScreen }) => {
  const { userDetails } = useSelector((state) => state.authReducer); // store data
  //state
  const [isConsent, setIsConsent] = useState(false);
  const [timeText, setTimeText] = useState('');

  const questionsJSON = useDownloadFileQuery(userDetails?.classID);
  const assessmentDuration = parseInt(questionsJSON?.data?.body?.assessment_duration, 10);
  
  const convertTimeToText = async (seconds) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
      
        let hoursText = '';
        let minutesText = '';
      
        if (hours > 1) {
          hoursText = `${hours} hours`;
        } else if (hours === 1) {
          hoursText = '1 hour';
        }
      
        if (minutes > 1) {
          minutesText = `${minutes} minutes`;
        } else if (minutes === 1) {
          minutesText = '1 minute';
        }
      
        if (hoursText && minutesText) {
          resolve(`${hoursText} and ${minutesText}`);
        } else if (hoursText) {
          resolve(hoursText);
        } else if (minutesText) {
          resolve(minutesText);
        }
      }, 2000); // Simulate a delay of 2 seconds
    });
  }

  //methods
  const handleStartAssessment = () => {
    if (isConsent) {
      toggleFullScreen();
      // window.open("/assesment", "_blank", "noopener,noreferrer");
    }
  };

  //async
  useEffect(() => {
    const keysToRemove = ["showIntro"];
    removeLocalStorageItems(keysToRemove);
  }, []);

  useEffect(() => {
    const fetchTimeText = async () => {
      const text = await convertTimeToText(assessmentDuration);
      setTimeText(text);
    };

    fetchTimeText();
  }, [assessmentDuration]);

  if (timeText === '') {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading your Assessment, please wait...
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      {/* <!-- Content: Start --> */}

      <div className="container-fluid flex-grow-1 container-p-y">
        <div
          className="row gap-3"
          style={{
            padding: "0rem",
            justifyContent: "space-between",
          }}
        >
          <div className="">
            <div
              className="row p-3 justify-content-center align-items-center"
              style={{color: "#000000" }}
            >
              <span
                className="justify-content-center align-items-center"
                style={{ fontSize: "x-large" }}
              >
                <img src={logoDark} alt="Logo" height="32px" width="32px" />{" "}
                <b>Assessli</b>
              </span>
            </div>
          </div>
        </div>

        <div
          className="scroller_wrapper"
          style={{
            // border: "1px solid red",
            height: "450px",
            overflowY: "scroll",
          }}
        >
          {/* <!-- Assessment Instructions --> */}
          <div className="container mt-4"
            style={{
              textAlign: "left",
            }}
          >
            <h2>Assessment Instructions</h2>
            <p>
              <strong>Exam Conduct:</strong>
            </p>
            <ol 
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                listStyleType: "disc"
              }}
            >
              <li>
                Ensure a quiet and distraction-free environment for the duration
                of the exam.
              </li>
              <li>
                Do not communicate with anyone during the assessment, including
                through messaging or social media platforms.
              </li>
              <li>
                Any attempt to cheat or use unauthorized materials will result
                in immediate disqualification.
              </li>
            </ol>
            <p>
              <strong>Exam Instructions:</strong>
            </p>
            <ol
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                listStyleType: "disc"
              }}
            >
              <li>Read all questions carefully before answering.</li>
              <li>
                The duration of this exam is <strong>{timeText}</strong>. Manage
                your time wisely to complete all sections within the allocated
                time frame.
              </li>
              <li>Save your progress periodically to prevent loss of work.</li>
              <li>
                Once started, the timer will continue to run even if you close
                the browser window.
              </li>
              <li>
                Note that the exam will automatically submit when the allotted
                time elapses. Ensure all responses are finalized and submitted
                before this time. Late submissions will not be accepted.
              </li>
            </ol>
            <label htmlFor="declaration">
              <input
                type="checkbox"
                name="declaration"
                id="declaration"
                required={true}
                value={isConsent}
                onChange={(e) => setIsConsent(e.target.checked)}
              />{" "}
              I hereby declare that I will abide by the rules and regulations
              set forth for this assessment. I understand that any violation of
              these rules may result in penalties, including disqualification
              from the assessment.
            </label>
            <br />
            <br />

            {/* <!-- Assessment Status and Actions --> */}
          </div>
        </div>
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <button
            className="btn btn-primary waves-effect waves-light"
            disabled={!isConsent}
            onClick={() => toggleFullScreen()}
          >
            Start Assessment
          </button>
        </div>
      </div>
      <div className="content-backdrop fade"></div>
    </div>
  );
};

export default AssesmentIntro;