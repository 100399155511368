import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTimerModal } from "../slices/modalSlice";
import { setTime } from "../slices/timerSlice";

const CountdownTimer = () => {
  const dispatch = useDispatch();
  const { isTimerCompletedModal } = useSelector((state) => state.modalReducer);
  const remainingTime = useSelector(
    (state) => state.timerReducer.remainingTime
  );
  // const examDuration = 30;
  const examDuration = useSelector((state) => state.authReducer.examDuration);

  useEffect(() => {
    if (remainingTime === -1 && examDuration !== null) {
      dispatch(setTime(examDuration));
    } else {
      dispatch(setTime(remainingTime));
    }
  }, [remainingTime, examDuration]);

  useEffect(() => {
    console.log("remainingTime", remainingTime);
    if (remainingTime === 0) {
      console.log("Time is up!");
      dispatch(setTimerModal(true));
      localStorage.removeItem("remainingTime");
      return;
    }
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        const newTime = prevTime - 1;
        return newTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTime]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours > 0 ? `${hours} h ` : ""}${minutes} m ${seconds
      .toString()
      .padStart(2, "0")} s`;
  };

  useEffect(() => {
    if (remainingTime === 0 && isTimerCompletedModal) {
      dispatch(setTimerModal(true));
    }
  }, [remainingTime, dispatch, isTimerCompletedModal]);

  return <div>{formatTime(remainingTime)}</div>;
};

export default CountdownTimer;