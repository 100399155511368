import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  remainingTime: localStorage.getItem("remainingTime")
    ? parseInt(localStorage.getItem("remainingTime"), 10)
    : -1, // default to 1 hour
};

const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    decrementTime: (state) => {
      if (state.remainingTime > 0) {
        state.remainingTime -= 1;
        localStorage.setItem("remainingTime", state.remainingTime);
      }
    },
    setTime: (state, action) => {
      state.remainingTime = action.payload;
      localStorage.setItem("remainingTime", state.remainingTime);
    },
    resetTimer: (state, action) => {
      state.remainingTime = action.payload;
      localStorage.setItem("remainingTime", state.remainingTime);
    },
    clearTimer: (state) => {
      state.remainingTime = 0;
      localStorage.removeItem("remainingTime");
    },
  },
});

export const { decrementTime, setTime, resetTimer, clearTimer } =
  timerSlice.actions;

export default timerSlice.reducer;
