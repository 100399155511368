import React from "react";

const SelectBox = ({ label, name, id, options, value, onChange }) => {
  return (
    <div className="input_box">
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      <select name={name} id={id} value={value} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
