import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setConfirmationModal } from "../../../slices/modalSlice";
import { getTotalQuestionCount } from "../../../utils/commonHelper";

const ConfirmationModal = ({
  title,
  text,
  onClose,
  totalResponseCount,
  allQuestions,
  handleSubmit,
}) => {
  //misc
  const dispatch = useDispatch();

  //method
  const closeModal = () => {
    dispatch(setConfirmationModal(false));
  };

  return (
    <div className="attempted_q_modal">
      <div className="modal-box">
        <div className="upper_section">
          <div className="left_section">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 8px",
                backgroundColor: "#5DC983",
                borderRadius: "50%",
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: "white", fontSize: "28px" }}
              />
            </div>
            <div className="right_side">
              <span style={{ fontWeight: "500", color: "#A5AAB5" }}>
                Attempted
              </span>
              <span style={{ fontWeight: "700", color: "#121212" }}>
                {totalResponseCount} question
              </span>
            </div>
          </div>
          <div className="right_section">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 10px",
                backgroundColor: "#B6B9FE",
                borderRadius: "50%",
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: "white", fontSize: "28px" }}
              />
            </div>

            <div className="right_side">
              <span style={{ fontWeight: "500", color: "#A5AAB5" }}>
                Unattempted
              </span>
              <span style={{ fontWeight: "700", color: "#121212" }}>
                {/* {getTotalQuestionCount(
                  allQuestions?.questionsListAsPerCategory
                )}{" "} */}
                {getTotalQuestionCount(
                  allQuestions?.questionsListAsPerCategory
                ) - totalResponseCount}{" "}
                question
              </span>
            </div>
          </div>
        </div>

        <div className="lower_section">
          <p
            style={{
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "start",
              color: "#121212",
              margin: 0,
            }}
          >
            Do you still want to submit?
          </p>

          <div className="buttons">
            <button className="go-back" onClick={closeModal}>
              Go back
            </button>
            <button className="" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
