import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setUserDetails, setUserInfoTrue } from "../../slices/authSlice";
import InputBox from "../../components/InputFields/InputBox";
import SelectBox from "../../components/InputFields/SelectBox";
import illustration from "../../assets/images/backgrounds/registration.svg";
import IndiaFlag from "../../assets/images/elements/flag.png";
import { removeLocalStorageItems } from "../../utils/commonHelper";
import { useRegisterUserMutation, useGetListQuery} from "../../apiSlices/question_submission_ApiSlice";
import { isValidPhoneNumber } from "libphonenumber-js";
import ShowToast from "../../components/Toast";

const Register = () => {
  const [classoptions, setClassOptions] = useState([]);
  const [classoptionsisLoading, setClassOptionsIsLoading] = useState(true);
  //queries n mutation
  const [registerUser, { isLoading, isSuccess, isError, error }] =
    useRegisterUserMutation();
  //misc
  const dispatch = useDispatch();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check the initial screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //get classOptions -> usiGetListQuery to get list of assessments from S3
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://new-api-server-318620872792.us-west1.run.app/list/English`);
        const listData = await response.json();
        if (listData) {
          console.log('List data fetched', listData);
          const options = listData.map((filePath) => {
            const classNumber = filePath.match(/assessment_(\d+)/)[1]; // Extract class number
            return { value: `class_${classNumber}`, label: `Class ${classNumber}` };
          });
  
          options.unshift({ value: "", label: "Select" }); // Add first element
          setClassOptions(options); // Update state
          setClassOptionsIsLoading(false); // Set loading to false
          console.log('Class options', options);
        }
      } catch (error) {
        console.error('Failed to fetch list data:', error);
      }
    };
  
    fetchData();
  }, []);

  // const classoptions = [
  //   { value: "", label: "Select" },
  //   { value: "class_3", label: "Class 3" },
  //   { value: "class_8", label: "Class 8" },
  //   { value: "class_9", label: "Class 9" },
  //   { value: "class_10", label: "Class 10" },
  // ];
  const boardOptions = [
    { value: "", label: "Select" },
    { value: "CBSE", label: "CBSE" },
    { value: "ICSE", label: "ICSE" },
    { value: "State", label: "State Board" },
  ];

  //state
  const [formData, setFormData] = useState({
    fullName: "",
    rollNumber: "",
    classID: "",
    schoolName: "",
    board: "",
    phoneNo: "",
    email: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const { fullName, rollNumber, classID, schoolName, board, phoneNo, email } =
      formData;

    const isPhoneValid =
      isValidPhoneNumber(phoneNo, "IN") && phoneNo.length === 10;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (
      fullName &&
      rollNumber &&
      classID &&
      schoolName &&
      board &&
      phoneNo &&
      isPhoneValid &&
      email &&
      isEmailValid
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  //methods
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUser(formData).unwrap();
      console.log("Form Data:", formData);
      localStorage.clear();
      const keysToRemove = ["remainingTime", "showIntro"];
      removeLocalStorageItems(keysToRemove);
      dispatch(setUserInfoTrue());
      dispatch(setUserDetails(formData));
      window.open("/", "_blank", "noopener,noreferrer");
    } catch (err) {
      console.error("Failed to register user:", err);
      ShowToast({
        type: "error",
        message: err?.data?.message || "Something went wrong.",
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handleButtonClick = () => {
    ShowToast({
      type: "error",
      message: "This is a error message!",
      position: "top-right",
      autoClose: 5000,
    });
  };

  return (
    isMobileView ? (
      <div className="registration_container">
        <div className="main_container_mobile">
          <img src="/assessli_b.png" alt="logo" className="logo"/>
          <div>
            <h1>Register</h1>
            <h6>Let's know you better before we get started...</h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="same-line-input">
              <InputBox
                className="input-fields"
                type="text"
                label="Full Name"
                name="fullName"
                id="full_name"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
              />
              <InputBox
                className="input-fields"
                type="text"
                label="Roll Number"
                name="rollNumber"
                id="roll_number"
                placeholder="Enter your roll number"
                value={formData.rollNumber}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              <InputBox
                type="text"
                label="School Name"
                name="schoolName"
                id="school_name"
                placeholder="Enter your school name"
                value={formData.schoolName}
                onChange={handleChange}
              />
              <SelectBox
                label="Class"
                name="classID"
                id="class"
                options={classoptionsisLoading ? [{ value: "", label: "Loading..." }] : classoptions}
                value={formData.classID}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              <label className="phone_input_label">
                Phone Number
                <div className="phone_input">
                  <div className="country_code">
                    <img src={IndiaFlag} alt="country_flag" />
                    <span>+91</span>
                  </div>
                  <input
                    type="tel"
                    className="input_box"
                    placeholder="Enter your phone number"
                    style={{ width: "100%" }}
                    name="phoneNo"
                    id="phone_number"
                    value={formData.phoneNo}
                    onChange={handleChange}
                  />
                </div>
              </label>
              <SelectBox
                label="Board"
                name="board"
                id="board"
                options={boardOptions}
                value={formData.board}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input-email" style={{display: 'flex', alignItems: 'flex-end'}}>
              <InputBox
                className="email-input"
                type="email"
                label="Email Id"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
              <button className="btn" type="submit" disabled={!isFormValid}>
                {isLoading ? "Loading..." : "Sign Up"}
              </button>
            </div>
          </form>
          <img src="/Exams-bro.svg" alt="Illustration" className="registration-illustration"/>
          <div className="footer_container">
            <p>© {new Date().getFullYear()} by OneOath</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="registration_container">
        <div className="left_container">
          <img src="/assessli_b.png" alt="logo" className="logo"/>
          <div className="illustrations">
            <img src="/Uploading-bro.svg" alt="Illustration" className="registration-illustration-clouds"/>
            <img src="/Exams-bro.svg" alt="Illustration" className="registration-illustration"/>
          </div>
        </div>
        <div className="right_container">
          <div>
            <h1>Register</h1>
            <h6>Let's know you better before we get started...</h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="same-line-input">
              <InputBox
                className="input-fields"
                type="text"
                label="Full Name"
                name="fullName"
                id="full_name"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
              />
              <InputBox
                className="input-fields"
                type="text"
                label="Roll Number"
                name="rollNumber"
                id="roll_number"
                placeholder="Enter your roll number"
                value={formData.rollNumber}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              <InputBox
                type="text"
                label="School Name"
                name="schoolName"
                id="school_name"
                placeholder="Enter your school name"
                value={formData.schoolName}
                onChange={handleChange}
              />
              <SelectBox
                label="Class"
                name="classID"
                id="class"
                options={classoptionsisLoading ? [{ value: "", label: "Loading..." }] : classoptions}
                value={formData.classID}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input">
              <label className="phone_input_label">
                Phone Number
                <div className="phone_input">
                  <div className="country_code">
                    <img src={IndiaFlag} alt="country_flag" />
                    <span>+91</span>
                  </div>
                  <input
                    type="tel"
                    className="input_box"
                    placeholder="Enter your phone number"
                    style={{ width: "100%" }}
                    name="phoneNo"
                    id="phone_number"
                    value={formData.phoneNo}
                    onChange={handleChange}
                  />
                </div>
              </label>
              <SelectBox
                label="Board"
                name="board"
                id="board"
                options={boardOptions}
                value={formData.board}
                onChange={handleChange}
              />
            </div>
            <div className="same-line-input-email" style={{display: 'flex', alignItems: 'flex-end'}}>
              <InputBox
                className="email-input"
                type="email"
                label="Email Id"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
              <button className="btn" type="submit" disabled={!isFormValid}>
                {isLoading ? "Loading..." : "Sign Up"}
              </button>
            </div>
          </form>
          {/* <button onClick={handleButtonClick}>TOAST</button> */}
          {/* <p style={{ fontSize: "18px", textAlign: "center", margin: 0 }}>
            Already have an account?{" "}
            <NavLink to="/login" style={{ color: "#5e16da", fontWeight: "500" }}>
              Sign in
            </NavLink>
          </p> */}
          <div className="footer_container">
            <p>© {new Date().getFullYear()} by OneOath</p>
          </div>
        </div>
      </div>
    )
  );
};

export default Register;
