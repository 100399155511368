import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setConfirmationModal,
  setSuccessModal,
} from "../../../slices/modalSlice";

const SuccessModal = ({ title, text, onClose }) => {
  //misc
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //method
  const handleReview = () => {
    navigate("/review");
  };

  const handleThankYouPage = () => {
    navigate("/thankYou");
    dispatch(setSuccessModal(false));
  };

  const handleCloseReviewModal = () => {
    dispatch(setSuccessModal(false));
  };

  return (
    <div className="popup_modal">
      <div className="modal-box">
        <FontAwesomeIcon icon={faCircleCheck} className="icon-success fa-3x" />
        <h2> {title} </h2>
        {/* <h3> {text} </h3> */}

        <div className="buttons">
          <button className="close-btn" onClick={() => handleReview()}>
            Review
          </button>
          <button className="close-btn" onClick={() => handleThankYouPage()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
