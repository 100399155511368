import { createSlice, current } from "@reduxjs/toolkit";

const getInitialMenuState = () => {
  const savedState = localStorage.getItem("menuCollapsed");
  return savedState ? JSON.parse(savedState) : false;
};

const getInitialUserDetails = () => {
  const savedDetails = localStorage.getItem("userDetails");
  return savedDetails ? JSON.parse(savedDetails) : null;
};

const getStartTimer = () => {
  const savedDetails = localStorage.getItem("startTimer");
  return savedDetails ? JSON.parse(savedDetails) : false;
};

const getCurrentQuestion = () => {
  const savedDetails = localStorage.getItem("currentQuestion");
  return savedDetails ? JSON.parse(savedDetails) : null;
}

const getExamDuration = () => {
  const savedDetails = localStorage.getItem("examDuration");
  return savedDetails ? JSON.parse(savedDetails) : 0;
}

const initialState = {
  name: "auth",
  userInfo: getInitialUserDetails() ? true : false,
  userDetails: getInitialUserDetails(),
  isMenuCollapsed: getInitialMenuState(),
  sectionTitle: "",
  isStartTimer: getStartTimer(),
  assessmentID: "",
  currentSectionIndex: 0,
  noOfQuestions: [],
  currentQuestion: getCurrentQuestion(),
  examDuration: getExamDuration(),
  questionsData: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    toggleMenuCollapse: (state) => {
      state.isMenuCollapsed = !state.isMenuCollapsed;
      localStorage.setItem("menuCollapsed", state.isMenuCollapsed);
    },
    setUserInfoTrue: (state) => {
      state.userInfo = true;
    },
    setSectionTitle: (state, action) => {
      state.sectionTitle = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
    },
    setStartTimer: (state, action) => {
      state.isStartTimer = action.payload;
      localStorage.setItem("startTimer", JSON.stringify(action.payload));
    },
    setAssessmentID: (state, action) => { // Added reducer for assessmentID
      state.assessmentID = action.payload;
      localStorage.setItem("assessmentID", action.payload); // Store as a string
    },
    setCurrentSectionIndex: (state, action) => {
      state.currentSectionIndex = action.payload;
      localStorage.setItem("currentSectionIndex", action.payload);
    },
    setNoOfQuestions: (state, action) => {
      state.noOfQuestions = action.payload;
      localStorage.setItem("noOfQuestions", action.payload);
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
      localStorage.setItem("currentQuestion", JSON.stringify(action.payload));
    },
    setExamDuration: (state, action) => {
      state.examDuration = action.payload;
      localStorage.setItem("examDuration", action.payload);
    },
    setQuestionsJSON: (state, action) => {
      state.allquestions = action.payload;
      localStorage.setItem("questionsJSON", JSON.stringify(action.payload));
    }
  },
});

export const {
  toggleMenuCollapse,
  setUserInfoTrue,
  setSectionTitle,
  setUserDetails,
  setStartTimer,
  setAssessmentID,
  setCurrentSectionIndex,
  setNoOfQuestions,
  setCurrentQuestion,
  setExamDuration,
  setQuestionsJSON,
} = authSlice.actions;

export default authSlice.reducer;
