import React from "react";

const InputBox = ({ type, label, name, id, placeholder, value, onChange }) => {
  return (
    <div className="input_box">
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputBox;
