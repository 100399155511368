// routeConfig.js
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

//misc
import App from "../App";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";

// unauth
import LoginScreen from "../screens/unauth/SignIn";
import RegisterScreen from "../screens/unauth/Register";

// auth
import Assesment from "../screens/auth/Assesment";
import ReviewPage from "../screens/auth/Review";
import ThankYouScreen from "../screens/auth/ThankYouScreen";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Assesment />} />
        <Route path="/review" element={<ReviewPage />} />
        <Route path="/thankYou" element={<ThankYouScreen />} />
      </Route>

      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Route>
  )
);
