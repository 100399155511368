import React, { useRef, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Label, Cell } from 'recharts';
import html2canvas from 'html2canvas';

const brightTraits = ["LOGIC", "CONCENTRATION", "FOCUS", "COGNITIVE SKILL", "RETENTION POWER", "HARD WORKING", "STUDY HABIT", "CONSCIOUSNESS"];
const darkTraits = ["SILLY MISTAKES", "KNOWLEDGE GAP", "IMPULSIVE", "LEARNING GAP"];

const getColor = (trait, type) => {
  if (brightTraits.includes(trait)) {
    switch (type) {
      case 'you':
        return "#212d20";
      case 'avg':
        return "#577754";
      case 'ideal':
        return "#89a987";
      default:
        return "#000";
    }
  } else if (darkTraits.includes(trait)) {
    switch (type) {
      case 'you':
        return "#501817";
      case 'avg':
        return "#b53936";
      case 'ideal':
        return "#db8684";
      default:
        return "#000";
    }
  }
}

const CustomLegend = ({ payload, title }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', borderRadius: '5px', margin: '5px 130px' }}>
    <span style={{ marginBottom: '5px', fontWeight: 'bold', fontSize: '12px' }}>{title}</span>
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', border: '1px solid #ccc', padding: '5px'}}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', fontSize: '12px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}></div>
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  </div>
);

const MainChartComponent = ({ onCapture, data, trait_names }) => {
  const chartRef = useRef(null);
  const chartData = trait_names.map(trait_name => ({
    name: trait_name,
    you: data.trait_data[trait_name].score,
    avg: data.trait_data[trait_name].average_score,
    ideal: data.trait_data[trait_name].ideal_score,
  }));

  useEffect(() => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        onCapture(imgData);
      });
    }
  }, [chartRef, onCapture]);

  return (
    <div ref={chartRef} style={{ position: 'absolute', top: '-9999px', left: '-9999px', alignItems: 'center', width: '900px', height: '500px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <CustomLegend 
          title="Bright Traits" 
          payload={[
            { value: 'You', color: "#212d20" },
            { value: 'Average', color: "#577754" },
            { value: 'Ideal', color: "#89a987" }
          ]} 
        />
        <CustomLegend 
          title="Dark Traits" 
          payload={[
            { value: 'You', color: "#501817" },
            { value: 'Average', color: "#b53936" },
            { value: 'Ideal', color: "#db8684" }
          ]} 
        />
      </div>
      <h3 style={{ textAlign: 'center' }}>Trait wise Score</h3> {/* Chart title */}
      <BarChart
        width={900}
        height={250}
        data={chartData}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 100,
        }}
      >
        <XAxis dataKey="name" tick={{ angle: -45, textAnchor: 'end', fontSize: 10 }} interval={0} >
          <Label value="Traits" offset={-90} position="insideBottom" />
        </XAxis>
        <YAxis>
          <Label value="Score" angle={-90} position='outside' />
        </YAxis>
        
        {['you', 'avg', 'ideal'].map((key, index) => (
          <Bar key={index} dataKey={key} isAnimationActive={false}>
            {chartData.map((entry, idx) => (
              <Cell key={`cell-${key}-${idx}`} fill={getColor(entry.name, key)} />
            ))}
          </Bar>
        ))}
      </BarChart>
    </div>
  );
};

export default MainChartComponent;